import i18next from "i18next";
import React, { useEffect, useState } from "react";

export default function Intro() {
  return (
    <div className="intro-container">
      <h5>{i18next.t("title1")}</h5>
      <p>{i18next.t("note4")}</p>
      <h5>{i18next.t("note5")}</h5>
      <p>{i18next.t("note6")}</p>
      <h5>예약은 어떻게 하나요?</h5>
      <p>
        신규 가입 회원님은 담당자에게 매칭 문의를 하여 인증이 완료 된후 예약
        가능합니다 .
      </p>
    </div>
  );
}
